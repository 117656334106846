<template>
  <div class="working-hours">
    <div class="grey-box p-3">
      <div class="title" style="text-align: center">
        {{
          $t(
            `${$store.getters.getTranslationKeyForAdmin}.settingsCompany.workingHours`
          )
        }}
      </div>

      <div class="table">
        <!-- Header -->
        <div class="row table-row head">
          <div class="col table-col day">
            {{
              $t(
                `${$store.getters.getTranslationKeyForAdmin}.settingsCompany.day`
              )
            }}
          </div>
          <div class="col table-col time">
            {{
              $t(
                `${$store.getters.getTranslationKeyForAdmin}.settingsCompany.time`
              )
            }}
          </div>
          <div class="col table-col break">
            {{
              $t(
                `${$store.getters.getTranslationKeyForAdmin}.settingsCompany.break`
              )
            }}
          </div>
          <div class="col table-col edit">
            {{
              $t(
                `${$store.getters.getTranslationKeyForAdmin}.settingsCompany.edit`
              )
            }}
          </div>
        </div>

        <!-- Data -->
        <div
          class="row table-row"
          v-for="(values, day) in workingHours"
          :key="day"
        >
          <div class="col table-col day">
            {{
              $t(
                `${
                  $store.getters.getTranslationKeyForAdmin
                }.settingsCompany.${day.toLowerCase()}`
              )
            }}
          </div>
          <div class="col table-col time">
            <template v-if="editDay === day">
              <span class="start-time">
                <TimePicker
                  v-model="values.hours.from"
                  :minute-interval="5"
                  close-on-complete
                  auto-scroll
                  class="time-picker"
                />
              </span>
              <span class="separator">-</span>
              <span class="end-time">
                <TimePicker
                  v-model="values.hours.to"
                  :minute-interval="5"
                  :hour-range="timeEndRange(day).hour"
                  :minute-range="timeEndRange(day).minute"
                  hide-disabled-items
                  close-on-complete
                  auto-scroll
                  class="time-picker"
                />
              </span>
            </template>
            <template
              v-else-if="
                (values.hours.from && values.hours.from !== '') ||
                (values.hours.to && values.hours.to !== '')
              "
            >
              <span class="start-time">{{ values.hours.from }}</span>
              <span class="separator">-</span>
              <span class="end-time">{{ values.hours.to }}</span>
            </template>
            <template v-else>
              <span class="off">{{
                $t(
                  `${$store.getters.getTranslationKeyForAdmin}.settingsCompany.notWorking`
                )
              }}</span>
            </template>
          </div>
          <div class="col table-col break">
            <template v-if="editDay === day">
              <span class="start-time">
                <TimePicker
                  v-model="values.break.from"
                  :minute-interval="5"
                  :hour-range="pauseTimeRange(day).hour"
                  :minute-range="pauseTimeRange(day).minute"
                  hide-disabled-items
                  close-on-complete
                  auto-scroll
                  class="time-picker"
                />
              </span>
              <span class="separator">-</span>
              <span class="end-time">
                <TimePicker
                  v-model="values.break.to"
                  :minute-interval="5"
                  :hour-range="pauseTimeRange(day, true).hour"
                  :minute-range="pauseTimeRange(day, true).minute"
                  hide-disabled-items
                  close-on-complete
                  auto-scroll
                  class="time-picker"
                />
              </span>
            </template>
            <template
              v-else-if="
                (values.break.from && values.break.from !== '') ||
                (values.break.to && values.break.to !== '')
              "
            >
              <span class="start-time">{{ values.break.from }}</span>
              <span class="separator">-</span>
              <span class="end-time">{{ values.break.to }}</span>
            </template>
          </div>
          <div class="col table-col edit">
            <span @click="editDay = day">
              <EditIcon />
            </span>
          </div>
        </div>
      </div>

      <div class="form-control-custom-custom-group actions mt-3">
        <div class="form-control-custom half-w">
          <ButtonElement block orange-secondary>{{
            $t(
              `${$store.getters.getTranslationKeyForAdmin}.settingsCompany.cancel`
            )
          }}</ButtonElement>
        </div>
        <div class="form-control-custom half-w">
          <ButtonElement
            block
            orange
            @click="handleSave"
            :loading="isLoading"
            >{{
              $t(
                `${$store.getters.getTranslationKeyForAdmin}.settingsCompany.save`
              )
            }}</ButtonElement
          >
        </div>
      </div>
    </div>

    <!--    <div class="grey-box">-->
    <!--      <div class="title">Other</div>-->

    <!--      <div class="table">-->
    <!--        <div class="table-row head"></div>-->
    <!--        <div class="table-row">-->
    <!--          <div class="table-col icon">-->
    <!--            <TruckIcon />-->
    <!--          </div>-->
    <!--          <div class="table-col text">Delivery Information</div>-->
    <!--        </div>-->
    <!--        <div class="table-row">-->
    <!--          <div class="table-col icon">-->
    <!--            <FileIcon />-->
    <!--          </div>-->
    <!--          <div class="table-col text">Company Documents</div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>
import { ref, reactive } from '@vue/composition-api';
import EditIcon from '../Svgs/EditIcon';
import TimePicker from 'vue2-timepicker/src/vue-timepicker.vue';
import ButtonElement from '../FormControl/ButtonElement';
import TruckIcon from '../Svgs/TruckIcon';
import FileIcon from '../Svgs/FileIcon';
import store from '../../store';

const hoursEmptySet = () => ({
  hours: {
    from: '',
    to: '',
  },
  break: {
    from: '',
    to: '',
  },
});

export default {
  setup() {
    let isLoading = ref(false);

    const daysInWeek = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ];

    const workingHoursData = {};
    for (let day of daysInWeek) {
      let values =
        store.getters.workingHours &&
        store.getters.workingHours[day.toLowerCase()]
          ? store.getters.workingHours[day.toLowerCase()]
          : null;

      if (values) {
        if (values.hours.from == null) values.hours.from = '';
        if (values.hours.to == null) values.hours.to = '';
        if (values.break.from == null) values.break.from = '';
        if (values.break.to == null) values.break.to = '';
      }

      workingHoursData[day] = values || hoursEmptySet();
    }
    const workingHours = reactive(workingHoursData);
    const editDay = ref('');

    const handleSave = async () => {
      isLoading.value = true;
      await store.dispatch('updateUserInfo', { working_hours: workingHours });
      editDay.value = '';
      isLoading.value = false;
    };

    return {
      editDay,
      workingHours,
      handleSave,
      isLoading,
    };
  },
  methods: {
    timeEndRange(day) {
      if (!this.workingHours[day].hours.from) {
        return {
          hour: [],
          minute: [],
        };
      }

      const timeStart = this.workingHours[day].hours.from.split(':');
      const timeEnd = this.workingHours[day].hours.to.split(':');

      const hourRange = [];
      for (let i = 0; i < 24; ++i) {
        if (i < timeStart[0]) {
          continue;
        }

        hourRange.push(i);
      }

      const minuteRange = [];
      for (let i = 0; i < 60; i += 5) {
        if (timeEnd[0] == timeStart[0] && i <= timeStart[1]) {
          continue;
        }

        minuteRange.push(i);
      }

      return {
        hour: hourRange,
        minute: minuteRange,
      };
    },
    pauseTimeRange(day, isEndTime = false) {
      if (
        !this.workingHours[day].hours.from ||
        !this.workingHours[day].hours.to
      ) {
        return {
          hour: [],
          minute: [],
        };
      }

      if (isEndTime && !this.workingHours[day].break.from) {
        return {
          hour: [],
          minute: [],
        };
      }

      const timeStart = this.workingHours[day].hours.from.split(':');
      const timeEnd = this.workingHours[day].hours.to.split(':');
      const breakTimeStart = this.workingHours[day].break.from.split(':');
      const breakTimeEnd = this.workingHours[day].break.to.split(':');

      const hourRange = [];
      for (let i = 0; i < 24; ++i) {
        if (i < timeStart[0] || i > timeEnd[0]) {
          continue;
        }

        if (isEndTime && i < breakTimeStart[0]) {
          continue;
        }

        hourRange.push(i);
      }

      const minuteRange = [];
      for (let i = 0; i < 60; i += 5) {
        if (!isEndTime) {
          if (breakTimeStart[0] == timeStart[0] && i < timeStart[1]) {
            continue;
          }

          if (breakTimeStart[0] == timeEnd[0] && i > timeEnd[1]) {
            continue;
          }
        } else {
          if (breakTimeEnd[0] == timeStart[0] && i < timeStart[1]) {
            continue;
          }

          if (breakTimeEnd[0] == timeEnd[0] && i > timeEnd[1]) {
            continue;
          }

          if (breakTimeEnd[0] == breakTimeStart[0] && i <= breakTimeStart[1]) {
            continue;
          }
        }

        minuteRange.push(i);
      }

      return {
        hour: hourRange,
        minute: minuteRange,
      };
    },
  },
  components: { EditIcon, TimePicker, ButtonElement, TruckIcon, FileIcon },
};
</script>

<style lang="scss">
.company-info,
.working-hours {
  .grey-box {
    background-color: $hty-grey;
    border-radius: 0.625rem;

    .row {
      padding: 0 1rem;
    }
  }
}
.working-hours {
  .table-row {
    border-bottom: 1px solid $hty-medium-grey;

    &.head {
      color: $hty-orange;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  .table-col {
    padding: 0.8rem 1.5rem 0.8rem 0;

    &:last-child {
      padding-right: 0;
    }

    &.day {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      color: $hty-orange;
    }

    &.edit {
      text-align: right;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &.text {
      font-size: 1rem;
      color: $hty-dark-grey;
    }

    .separator {
      display: inline-block;
      margin: 0 0.2rem;
    }

    .off {
      color: $hty-orange;
    }
  }

  .time-picker {
    width: auto;

    .display-time {
      background-color: $hty-grey-background;
      padding: 2px 8px;
      border: none;
      box-shadow: none;
      width: 4.1rem;
      height: 1.43rem;
      font-size: 1rem;
      border-radius: 5px;
    }
  }
}
</style>
