<template>
  <div class="fourth-step">
    <div class="items row">
      <section
        class="drop-item col-12 col-lg-6"
        v-for="(companyTypeDoc, i) in $store.getters.companyTypeDocs"
        :key="i"
      >
        <div class="row">
          <div class="col-8">
            <label class="label">
              {{ companyTypeDoc.name }}
              <img
                v-if="
                  userCompanyDoc(companyTypeDoc.key) &&
                  userCompanyDoc(companyTypeDoc.key).verified
                "
                class="pb-1 pl-1"
                :src="require('@/assets/pics/check_mark_green.png')"
              />
            </label>
          </div>
          <div class="col-4 text-right">
            <a
              v-if="userCompanyDoc(companyTypeDoc.key)"
              class="document-preview-link"
              :href="userCompanyDoc(companyTypeDoc.key).file_path"
              target="_blank"
            >
              {{ $t('serviceProvider.settingsCompany.viewDocument') }}
            </a>
            <span v-else class="no-document">
              {{ $t('serviceProvider.settingsCompany.noDocument') }}
            </span>
          </div>
        </div>
        <ValidationProvider v-slot="{ validate, errors }">
          <div
            :id="companyTypeDoc.key"
            class="droppable"
            v-on="targetListeners"
          >
            <div
              v-if="!$store.getters.adminRegistrationData[companyTypeDoc.key]"
              class="empty"
            >
              <span class="text">
                {{ $t('serviceProvider.signUp.dropOr') }}
              </span>
              <ButtonElement @click="toggleFileBrowse(companyTypeDoc.key)" link>
                {{ $t('serviceProvider.signUp.browse') }}
                <input
                  :id="companyTypeDoc.key + '-input'"
                  type="file"
                  class="d-none"
                  @change="setFile(companyTypeDoc.key, $event.target.files[0])"
                />
              </ButtonElement>
            </div>
            <div v-else class="text">
              {{
                $store.getters.adminRegistrationData[companyTypeDoc.key].name
              }}
              <div class="text-remove" @click="removeFile(companyTypeDoc.key)">
                <XIcon width="12" height="12" />
              </div>
            </div>
          </div>
          <div class="error">
            {{ errors[0] }}
          </div>
        </ValidationProvider>
      </section>
    </div>

    <small class="text-secondary">
      Bitte beachten Sie: Werden Unterlagen, die schon verifiziert wurden,
      ersetzt, wird der Verifizierungsprozess erneut durchgeführt.
    </small>

    <div class="actions">
      <ButtonElement @click="submit" :loading="isLoading" orange>
        {{ $t(`serviceProvider.signUp.send`) }}
      </ButtonElement>
    </div>
  </div>
</template>

<script>
import ButtonElement from '../FormControl/ButtonElement.vue';
import useDragAndDrop from '../../composables/useDragAndDrop';
import { findLast } from 'lodash';

export default {
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    targetListeners() {
      const { targetListeners } = useDragAndDrop(
        {
          dropHandler: (files, action) => {
            this.setFile(action, files[0]);
          },
        },
        { dropFile: true }
      );

      return targetListeners;
    },
  },
  created() {
    this.$store.dispatch('fetchCompanyTypeDocs', {
      company_type: this.$store.getters.userInfo.companyType,
    });
  },
  methods: {
    toggleFileBrowse(key) {
      document.getElementById(key + '-input').click();
    },
    setFile(key, file) {
      if (!file) {
        return;
      }

      this.$store.commit('setAdminRegistrationData', {
        key,
        value: file,
      });
      this.$forceUpdate();
    },
    removeFile(key) {
      this.$store.commit('setAdminRegistrationData', {
        key,
        value: null,
      });
      this.$forceUpdate();
    },
    userCompanyDoc(key) {
      return findLast(this.$store.getters.userCompanyDocs, e => e.key == key);
    },
    async submit() {
      const payload = {};

      for (const companyDoc in this.$store.getters.companyTypeDocs) {
        if (
          Object.hasOwnProperty.call(
            this.$store.getters.companyTypeDocs,
            companyDoc
          )
        ) {
          const el = this.$store.getters.companyTypeDocs[companyDoc];

          if (this.$store.getters.adminRegistrationData[el.key]) {
            payload[el.key] = this.$store.getters.adminRegistrationData[el.key];
          }
        }
      }

      this.isLoading = true;
      await this.$store.dispatch('uploadFilesCompanyUser', payload);
      this.isLoading = false;
    },
  },
  components: {
    ButtonElement,
  },
};
</script>

<style lang="scss" scoped>
.fourth-step {
  width: 100%;
}
.actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;

  button {
    flex: 0 0 50%;
    max-width: 200px;
  }
}
.label {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2;
  color: $hty-dark-grey;
  margin-bottom: 0.3rem;
  display: inline-block;
  max-width: 460px;
  min-width: 430px;
}
.document-preview-link {
  color: $hty-orange;
}
.no-document {
  color: $hty-dark-grey;
}
.drop-item {
  margin-bottom: 0.7rem;
}
.droppable {
  position: relative;
  border: 2px dashed #d3d3d3;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  padding: 10px;

  & .text {
    margin-right: 5px;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.2;
    color: $hty-dark-grey;
  }

  .text-remove {
    position: absolute;
    top: 3px;
    right: 5px;
    cursor: pointer;
  }
}
.error {
  margin-top: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 1.2;
  color: $hty-red2;
}
</style>
