<template>
  <div class="company-settings-container">
    <router-view />
    <SuccessNotification>
      {{ $t('general.successfulNotification') }}
    </SuccessNotification>
  </div>
</template>

<script>
import SuccessNotification from '../../components/Staff/SuccessNotification';
export default {
  components: { SuccessNotification },
};
</script>

<style lang="scss" scoped>
.company-settings-container {
  padding: 1.5rem 2rem;
  height: calc(100vh - 110px);
  overflow: auto;
}
</style>
