export const legalEntityOptions = [
  {
    label: 'Einzelunternehmen',
    value: 'Einzelunternehmen',
  },
  {
    label: 'Gesellschaft bürgerlichen Rechts (GbR)',
    value: 'Gesellschaft bürgerlichen Rechts (GbR)',
  },
  {
    label: 'Eingetragener Kaufmann (e.K.)',
    value: 'Eingetragener Kaufmann (e.K.)',
  },
  {
    label: 'Offene Handelsgesellschaft (OHG)',
    value: 'Offene Handelsgesellschaft (OHG)',
  },
  {
    label: 'Kommanditgesellschaft (KG)',
    value: 'Kommanditgesellschaft (KG)',
  },
  {
    label: 'Kommanditgesellschaft auf Aktien (KG aA)',
    value: 'Kommanditgesellschaft auf Aktien (KG aA)',
  },
  {
    label: 'Gesellschaft mit beschränkter Haftung (GmbH)',
    value: 'Gesellschaft mit beschränkter Haftung (GmbH)',
  },
  {
    label: 'Unternehmergesellschaft (haftungsbeschränkt)',
    value: 'Unternehmergesellschaft (haftungsbeschränkt)',
  },
  {
    label: 'Aktiengesellschaft (AG) (börsenorientiert)',
    value: 'Aktiengesellschaft (AG) (börsenorientiert)',
  },
  {
    label: 'Aktiengesellschaft (AG) (nicht börsenorientiert)',
    value: 'Aktiengesellschaft (AG) (nicht börsenorientiert)',
  },
  {
    label: 'GmbH & Co KG',
    value: 'GmbH & Co KG',
  },
  {
    label: 'Eingetragene Genossenschaft (eG)',
    value: 'Eingetragene Genossenschaft (eG)',
  },
  {
    label: 'Eingetragener Verein (e.V.)',
    value: 'Eingetragener Verein (e.V.)',
  },
  {
    label: 'Stiftungen',
    value: 'Stiftungen',
  },
  {
    label: 'Körperschaften / Unternehmen des öffentlichen Rechts',
    value: 'Körperschaften / Unternehmen des öffentlichen Rechts',
  },
  {
    label: 'Ltd. und vergleichbare europäische Rechtsformen',
    value: 'Ltd. und vergleichbare europäische Rechtsformen',
  },
];
