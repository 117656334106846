<template>
  <div class="company-info">
    <div class="grey-box p-3">
      <div class="title pt-2" style="text-align: center">
        {{
          $t(
            `${$store.getters.getTranslationKeyForAdmin}.settingsCompany.companyInformation`
          )
        }}
      </div>

      <ValidationObserver ref="updateInfoForm">
        <div class="form-control-custom-custom-group">
          <div class="form-control-custom full-w">
            <label class="label">{{
              $t(
                `${$store.getters.getTranslationKeyForAdmin}.settingsCompany.companyName`
              )
            }}</label>
            <InputElement
              v-model="companyInfoPayload.company_name"
              :placeholder="
                $t(
                  `${$store.getters.getTranslationKeyForAdmin}.settingsCompany.companyName`
                )
              "
              validation-rules="required"
            />
          </div>
        </div>

        <div class="form-control-custom-custom-group">
          <div class="form-control-custom full-w">
            <label class="label">{{
              $t(
                `${$store.getters.getTranslationKeyForAdmin}.settingsCompany.legalEntity`
              )
            }}</label>
            <ValidationProvider
              rules="required"
              v-slot="{ errors, failed, classes }"
            >
              <SelectElement
                v-model="companyInfoPayload.company_type"
                :value="companyInfoPayload.company_type"
                :options="legalEntityOptions"
                search
              />
              <div v-if="failed" class="error" :class="classes">
                {{ errors[0] }}
              </div>
            </ValidationProvider>
          </div>
        </div>

        <div
          class="form-control-custom-custom-group"
          v-if="$store.getters.categoriesOptions.length"
        >
          <div class="form-control-custom full-w">
            <label class="label">{{
              $t('serviceProvider.settingsCompany.businessCategory')
            }}</label>
            <ValidationProvider
              rules="required"
              v-slot="{ errors, failed, classes }"
            >
              <SelectElement
                v-model="companyInfoPayload.category"
                :value="companyInfoPayload.category"
                :options="$store.getters.categoriesOptions"
                search
                multi-select
                @dropdown-closed="handleFetchSubcategory"
              />
              <div v-if="failed" class="error" :class="classes">
                {{ errors[0] }}
              </div>
            </ValidationProvider>
          </div>
        </div>

        <div
          class="form-control-custom-custom-group"
          v-if="$store.getters.subcategoriesOptions.length"
        >
          <div class="form-control-custom full-w">
            <label class="label">{{
              $t('serviceProvider.settingsCompany.businessSubCategory')
            }}</label>
            <ValidationProvider
              rules="required"
              v-slot="{ errors, failed, classes }"
            >
              <SelectElement
                v-model="companyInfoPayload.sub_category"
                :value="companyInfoPayload.sub_category"
                :options="$store.getters.subcategoriesOptions"
                search
                multi-select
              />
              <div v-if="failed" class="error" :class="classes">
                {{ errors[0] }}
              </div>
            </ValidationProvider>
          </div>
        </div>

        <div
          class="form-control-custom-custom-group"
          v-if="$store.getters.countriesOptions.length"
        >
          <div class="form-control-custom full-w">
            <label class="label">{{
              $t(
                `${$store.getters.getTranslationKeyForAdmin}.settingsCompany.country`
              )
            }}</label>
            <ValidationProvider
              rules="required"
              v-slot="{ errors, failed, classes }"
            >
              <SelectElement
                v-model="companyInfoPayload.country"
                :value="companyInfoPayload.country"
                :options="$store.getters.countriesOptions"
                :placeholder="
                  $t(
                    `${$store.getters.getTranslationKeyForAdmin}.settingsCompany.country`
                  )
                "
                search
              />
              <div v-if="failed" class="error" :class="classes">
                {{ errors[0] }}
              </div>
            </ValidationProvider>
          </div>
        </div>

        <div class="form-control-custom-custom-group">
          <div class="form-control-custom half-w" style="flex: 0 0 40%">
            <label class="label">{{
              $t(
                `${$store.getters.getTranslationKeyForAdmin}.settingsCompany.city`
              )
            }}</label>
            <GooglePlacesAutocomplete
              :value="companyInfoPayload.city"
              search-type="(cities)"
              @select="selectCity"
              :placeholder="
                $t(
                  `${$store.getters.getTranslationKeyForAdmin}.settingsCompany.city`
                )
              "
            >
              <template #list-label="{ value }">
                {{ value.description }}
              </template>
            </GooglePlacesAutocomplete>
          </div>
          <div
            class="form-control-custom half-w"
            style="flex: 0 0 60%; padding-left: 0"
          >
            <label class="label">{{
              $t(
                `${$store.getters.getTranslationKeyForAdmin}.settingsCompany.address`
              )
            }}</label>
            <GooglePlacesAutocomplete
              :value="companyInfoPayload.address"
              search-type="address"
              @select="selectAddress"
              :placeholder="
                $t(
                  `${$store.getters.getTranslationKeyForAdmin}.settingsCompany.address`
                )
              "
            >
              <template #list-label="{ value }">
                {{ value.description }}
              </template>
            </GooglePlacesAutocomplete>
          </div>
        </div>

        <div class="form-control-custom-custom-group">
          <div class="form-control-custom full-w">
            <label class="label">{{
              $t(
                `${$store.getters.getTranslationKeyForAdmin}.settingsCompany.zipCode`
              )
            }}</label>
            <InputElement
              v-model="companyInfoPayload.zipcode"
              :placeholder="
                $t(
                  `${$store.getters.getTranslationKeyForAdmin}.settingsCompany.zipCode`
                )
              "
              validation-rules="required"
            />
          </div>
        </div>

        <div class="form-control-custom-custom-group">
          <div class="form-control-custom full-w">
            <label class="label">{{
              $t(
                `${$store.getters.getTranslationKeyForAdmin}.settingsCompany.phoneNumber`
              )
            }}</label>
            <InputElement
              v-model="companyInfoPayload.phone_number"
              :placeholder="
                $t(
                  `${$store.getters.getTranslationKeyForAdmin}.settingsCompany.zipCode`
                )
              "
              validation-rules="required|phone"
            />
          </div>
        </div>
      </ValidationObserver>

      <div class="form-control-custom-custom-group mt-4">
        <div class="form-control-custom half-w">
          <ButtonElement block orange-secondary>{{
            $t(
              `${$store.getters.getTranslationKeyForAdmin}.settingsCompany.cancel`
            )
          }}</ButtonElement>
        </div>
        <div class="form-control-custom half-w">
          <ButtonElement
            block
            orange
            @click="handleSave"
            :loading="isLoading"
            >{{
              $t(
                `${$store.getters.getTranslationKeyForAdmin}.settingsCompany.save`
              )
            }}</ButtonElement
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, reactive } from '@vue/composition-api';
import ButtonElement from '../FormControl/ButtonElement.vue';
import InputElement from '../FormControl/InputElement.vue';
import GooglePlacesAutocomplete from '@/components/FormControl/SelectElement/GooglePlacesAutocomplete.vue';
import SelectElement from '../FormControl/SelectElement/SelectElement.vue';
import store from '../../store';
import { legalEntityOptions } from '@/assets/legalEntities';

export default {
  watch: {
    '$store.getters.userInfo.companyType': function () {
      this.$store.dispatch('fetchCompanyTypeDocs', {
        company_type: this.$store.getters.userInfo.companyType,
      });
    },
  },
  computed: {
    legalEntityOptions: () => legalEntityOptions,
  },
  setup() {
    onMounted(() => {
      store.dispatch('fetchCategories', {
        type: store.getters.userInfo.type,
      });
      store.dispatch('fetchCountries');
      populateLocationsAndCategories();
    });

    let isLoading = ref(false);
    const updateInfoForm = ref(null);

    const selectCity = value => {
      companyInfoPayload.city = value.structured_formatting.main_text;
    };

    const selectAddress = value => {
      companyInfoPayload.address = value.structured_formatting.main_text;
    };

    const companyInfoPayload = reactive({
      company_name: store.getters.userInfo.companyName,
      phone_number: store.getters.userInfo.phoneNumber,
      company_type: store.getters.userInfo.companyType,
      country: '',
      city: '',
      address: '',
      zipcode: '',
      category: '',
      sub_category: '',
    });

    const populateLocationsAndCategories = async () => {
      await store.dispatch('fetchCompanyLocations');
      await store.dispatch('fetchCompanyCategories');

      if (store.getters.companyLocations[0]) {
        companyInfoPayload.country = store.getters.companyLocations[0].country;
        companyInfoPayload.city = store.getters.companyLocations[0].city;
        companyInfoPayload.address = store.getters.companyLocations[0].address;
        companyInfoPayload.zipcode = store.getters.companyLocations[0].zipcode;
      }

      companyInfoPayload.category =
        store.getters.companyCategoriesIds.categories;
      companyInfoPayload.sub_category =
        store.getters.companyCategoriesIds.subcategories;

      populateSubCategories();
    };

    const populateSubCategories = () => {
      store.dispatch(
        'fetchSubcategoriesByCategoryIds',
        Array.isArray(companyInfoPayload.category)
          ? companyInfoPayload.category.join(',')
          : companyInfoPayload.category.toString()
      );
    };

    const handleFetchSubcategory = () => {
      populateSubCategories();
    };

    const handleSave = async () => {
      isLoading.value = true;
      const valid = await updateInfoForm.value.validate();
      if (!valid) {
        isLoading.value = false;
        return;
      }

      const payload = Object.assign({}, companyInfoPayload);
      payload.category = companyInfoPayload.category.join();
      payload.sub_category = companyInfoPayload.sub_category.join();

      await store.dispatch('updateUserInfo', payload);
      isLoading.value = false;
    };

    return {
      updateInfoForm,
      companyInfoPayload,
      handleFetchSubcategory,
      handleSave,
      selectCity,
      selectAddress,
      isLoading,
    };
  },
  components: {
    ButtonElement,
    InputElement,
    SelectElement,
    GooglePlacesAutocomplete,
  },
};
</script>

<style lang="scss" scoped></style>
