var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"company-info"},[_c('div',{staticClass:"grey-box p-3"},[_c('div',{staticClass:"title pt-2",staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(_vm.$t( ((_vm.$store.getters.getTranslationKeyForAdmin) + ".settingsCompany.companyInformation") ))+" ")]),_c('ValidationObserver',{ref:"updateInfoForm"},[_c('div',{staticClass:"form-control-custom-custom-group"},[_c('div',{staticClass:"form-control-custom full-w"},[_c('label',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t( ((_vm.$store.getters.getTranslationKeyForAdmin) + ".settingsCompany.companyName") )))]),_c('InputElement',{attrs:{"placeholder":_vm.$t(
                ((_vm.$store.getters.getTranslationKeyForAdmin) + ".settingsCompany.companyName")
              ),"validation-rules":"required"},model:{value:(_vm.companyInfoPayload.company_name),callback:function ($$v) {_vm.$set(_vm.companyInfoPayload, "company_name", $$v)},expression:"companyInfoPayload.company_name"}})],1)]),_c('div',{staticClass:"form-control-custom-custom-group"},[_c('div',{staticClass:"form-control-custom full-w"},[_c('label',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t( ((_vm.$store.getters.getTranslationKeyForAdmin) + ".settingsCompany.legalEntity") )))]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var failed = ref.failed;
              var classes = ref.classes;
return [_c('SelectElement',{attrs:{"value":_vm.companyInfoPayload.company_type,"options":_vm.legalEntityOptions,"search":""},model:{value:(_vm.companyInfoPayload.company_type),callback:function ($$v) {_vm.$set(_vm.companyInfoPayload, "company_type", $$v)},expression:"companyInfoPayload.company_type"}}),(failed)?_c('div',{staticClass:"error",class:classes},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1)]),(_vm.$store.getters.categoriesOptions.length)?_c('div',{staticClass:"form-control-custom-custom-group"},[_c('div',{staticClass:"form-control-custom full-w"},[_c('label',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('serviceProvider.settingsCompany.businessCategory')))]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var failed = ref.failed;
              var classes = ref.classes;
return [_c('SelectElement',{attrs:{"value":_vm.companyInfoPayload.category,"options":_vm.$store.getters.categoriesOptions,"search":"","multi-select":""},on:{"dropdown-closed":_vm.handleFetchSubcategory},model:{value:(_vm.companyInfoPayload.category),callback:function ($$v) {_vm.$set(_vm.companyInfoPayload, "category", $$v)},expression:"companyInfoPayload.category"}}),(failed)?_c('div',{staticClass:"error",class:classes},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,false,4172319640)})],1)]):_vm._e(),(_vm.$store.getters.subcategoriesOptions.length)?_c('div',{staticClass:"form-control-custom-custom-group"},[_c('div',{staticClass:"form-control-custom full-w"},[_c('label',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('serviceProvider.settingsCompany.businessSubCategory')))]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var failed = ref.failed;
              var classes = ref.classes;
return [_c('SelectElement',{attrs:{"value":_vm.companyInfoPayload.sub_category,"options":_vm.$store.getters.subcategoriesOptions,"search":"","multi-select":""},model:{value:(_vm.companyInfoPayload.sub_category),callback:function ($$v) {_vm.$set(_vm.companyInfoPayload, "sub_category", $$v)},expression:"companyInfoPayload.sub_category"}}),(failed)?_c('div',{staticClass:"error",class:classes},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,false,799647249)})],1)]):_vm._e(),(_vm.$store.getters.countriesOptions.length)?_c('div',{staticClass:"form-control-custom-custom-group"},[_c('div',{staticClass:"form-control-custom full-w"},[_c('label',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t( ((_vm.$store.getters.getTranslationKeyForAdmin) + ".settingsCompany.country") )))]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var failed = ref.failed;
              var classes = ref.classes;
return [_c('SelectElement',{attrs:{"value":_vm.companyInfoPayload.country,"options":_vm.$store.getters.countriesOptions,"placeholder":_vm.$t(
                  ((_vm.$store.getters.getTranslationKeyForAdmin) + ".settingsCompany.country")
                ),"search":""},model:{value:(_vm.companyInfoPayload.country),callback:function ($$v) {_vm.$set(_vm.companyInfoPayload, "country", $$v)},expression:"companyInfoPayload.country"}}),(failed)?_c('div',{staticClass:"error",class:classes},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,false,2545386296)})],1)]):_vm._e(),_c('div',{staticClass:"form-control-custom-custom-group"},[_c('div',{staticClass:"form-control-custom half-w",staticStyle:{"flex":"0 0 40%"}},[_c('label',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t( ((_vm.$store.getters.getTranslationKeyForAdmin) + ".settingsCompany.city") )))]),_c('GooglePlacesAutocomplete',{attrs:{"value":_vm.companyInfoPayload.city,"search-type":"(cities)","placeholder":_vm.$t(
                ((_vm.$store.getters.getTranslationKeyForAdmin) + ".settingsCompany.city")
              )},on:{"select":_vm.selectCity},scopedSlots:_vm._u([{key:"list-label",fn:function(ref){
              var value = ref.value;
return [_vm._v(" "+_vm._s(value.description)+" ")]}}])})],1),_c('div',{staticClass:"form-control-custom half-w",staticStyle:{"flex":"0 0 60%","padding-left":"0"}},[_c('label',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t( ((_vm.$store.getters.getTranslationKeyForAdmin) + ".settingsCompany.address") )))]),_c('GooglePlacesAutocomplete',{attrs:{"value":_vm.companyInfoPayload.address,"search-type":"address","placeholder":_vm.$t(
                ((_vm.$store.getters.getTranslationKeyForAdmin) + ".settingsCompany.address")
              )},on:{"select":_vm.selectAddress},scopedSlots:_vm._u([{key:"list-label",fn:function(ref){
              var value = ref.value;
return [_vm._v(" "+_vm._s(value.description)+" ")]}}])})],1)]),_c('div',{staticClass:"form-control-custom-custom-group"},[_c('div',{staticClass:"form-control-custom full-w"},[_c('label',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t( ((_vm.$store.getters.getTranslationKeyForAdmin) + ".settingsCompany.zipCode") )))]),_c('InputElement',{attrs:{"placeholder":_vm.$t(
                ((_vm.$store.getters.getTranslationKeyForAdmin) + ".settingsCompany.zipCode")
              ),"validation-rules":"required"},model:{value:(_vm.companyInfoPayload.zipcode),callback:function ($$v) {_vm.$set(_vm.companyInfoPayload, "zipcode", $$v)},expression:"companyInfoPayload.zipcode"}})],1)]),_c('div',{staticClass:"form-control-custom-custom-group"},[_c('div',{staticClass:"form-control-custom full-w"},[_c('label',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t( ((_vm.$store.getters.getTranslationKeyForAdmin) + ".settingsCompany.phoneNumber") )))]),_c('InputElement',{attrs:{"placeholder":_vm.$t(
                ((_vm.$store.getters.getTranslationKeyForAdmin) + ".settingsCompany.zipCode")
              ),"validation-rules":"required|phone"},model:{value:(_vm.companyInfoPayload.phone_number),callback:function ($$v) {_vm.$set(_vm.companyInfoPayload, "phone_number", $$v)},expression:"companyInfoPayload.phone_number"}})],1)])]),_c('div',{staticClass:"form-control-custom-custom-group mt-4"},[_c('div',{staticClass:"form-control-custom half-w"},[_c('ButtonElement',{attrs:{"block":"","orange-secondary":""}},[_vm._v(_vm._s(_vm.$t( ((_vm.$store.getters.getTranslationKeyForAdmin) + ".settingsCompany.cancel") )))])],1),_c('div',{staticClass:"form-control-custom half-w"},[_c('ButtonElement',{attrs:{"block":"","orange":"","loading":_vm.isLoading},on:{"click":_vm.handleSave}},[_vm._v(_vm._s(_vm.$t( ((_vm.$store.getters.getTranslationKeyForAdmin) + ".settingsCompany.save") )))])],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }