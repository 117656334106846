import { ref, watch, watchEffect } from '@vue/composition-api';

export default function useDragAndDrop(
  {
    startHandler,
    endHandler,
    enterHandler,
    leaveHandler,
    overHandler,
    dropHandler,
  } = {},
  { dropFile = false } = {}
) {
  let sourceId;
  const onDragStart = event => {
    console.log('onDragStart event :>> ', event);
    event.srcElement.style.opacity = '0.4';
    // event.dataTransfer.effectAllowed = 'move';
    sourceId = event.srcElement.id;
    event.dataTransfer.setData('text/plain', event.srcElement.id);

    if (typeof startHandler === 'function') startHandler(sourceId);
  };
  const onDragEnter = event => {
    event.preventDefault();
    const targetId = event.currentTarget.id;
    console.log('onDragEnter event :>> ', event.currentTarget.id);

    if (typeof enterHandler === 'function') enterHandler(sourceId, targetId);
  };
  const onDragLeave = event => {
    event.preventDefault();
    const targetId = event.currentTarget.id;
    console.log('onDragLeave event :>> ', event);
    if (typeof leaveHandler === 'function') leaveHandler(sourceId, targetId);
  };
  const onDragOver = event => {
    event.preventDefault();
    const targetId = event.currentTarget.id;
    event.dataTransfer.dropEffect = 'move';
    if (typeof overHandler === 'function') overHandler(sourceId, targetId);
  };
  const onDrop = event => {
    event.preventDefault();
    // event.stopPropagation();
    console.log('onDrop event :>> ', event);
    const data = event.dataTransfer.getData('text/plain');
    const targetId = event.currentTarget.id;
    console.log('drop data :>> ', data, targetId);
    console.log('sourceId in drop :>> ', data);

    const files = event.dataTransfer.files;
    if (typeof dropHandler === 'function')
      dropHandler(!dropFile ? data : files, targetId);
  };
  const onDragEnd = event => {
    event.preventDefault();
    console.log('dragEnd', event);
    event.srcElement.style.opacity = '1';
    if (typeof endHandler === 'function') endHandler(sourceId);
  };

  const sourceListeners = {
    dragstart: onDragStart,
    dragend: onDragEnd,
  };
  const targetListeners = {
    drop: onDrop,
    dragover: onDragOver,
    dragenter: onDragEnter,
    dragleave: onDragLeave,
  };

  return {
    sourceListeners,
    targetListeners,
    listeners: {
      ...sourceListeners,
      ...targetListeners,
    },
  };
}
