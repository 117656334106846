<template>
  <div class="company">
    <div class="title" style="text-align: left">
      {{
        $t(`${$store.getters.getTranslationKeyForAdmin}.settingsCompany.title`)
      }}
    </div>

    <header class="row py-3 px-1 mx-0 mb-4">
      <div class="col-auto">
        <CompanyProfilePhoto />
      </div>
      <div class="col d-flex align-items-end">
        <div style="width: 100%">
          <div class="company-name">
            {{ $store.getters.userInfo.companyName }}
          </div>
          <div class="logout d-flex justify-content-end mt-3 mt-sm-0">
            <ButtonElement @click="handleLogout" orange>{{
              $t(
                `${$store.getters.getTranslationKeyForAdmin}.settingsCompany.logOut`
              )
            }}</ButtonElement>
          </div>
        </div>
      </div>
    </header>

    <div class="row mt-4">
      <CompanyInfo class="col-12 col-xl-6" />
      <WorkingHours class="col-12 col-xl-6 mt-4 mt-xl-0" />
    </div>

    <CompanyDescription />

    <div class="company-files mb-4" id="docs">
      <div class="title" style="text-align: center">
        {{ $t(`serviceProvider.notification.uploadDocuments`) }}
      </div>
      <CompanyFiles />
    </div>

    <div class="delete-account-container mb-4">
      <div class="subtitle">
        {{
          $t(
            `${$store.getters.getTranslationKeyForAdmin}.settingsCompany.deleteAccount`
          )
        }}
      </div>
      <div class="flex-box">
        <p>
          {{
            $t(
              `${$store.getters.getTranslationKeyForAdmin}.settingsCompany.yourAccountWillBePermanently`
            )
          }}
        </p>
        <ButtonElement @click="isOpen = true" orange-secondary>
          {{
            $t(
              `${$store.getters.getTranslationKeyForAdmin}.settingsCompany.deleteAccount`
            )
          }}
        </ButtonElement>
      </div>

      <Modal :open="isOpen" @backdropClick="isOpen = false">
        <ConfirmationModal
          @cancel="isOpen = false"
          @approve="handleDeleteAcc"
          color-approve-button="green"
        >
          <template #header>
            {{
              $t(
                `${$store.getters.getTranslationKeyForAdmin}.settingsCompany.deleteAccount`
              )
            }}
          </template>
          <template #default>
            {{
              $t(
                `${$store.getters.getTranslationKeyForAdmin}.settingsCompany.deletingYourAccountWillRemove`
              )
            }}
          </template>
        </ConfirmationModal>
      </Modal>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';
import ButtonElement from '../../../components/FormControl/ButtonElement.vue';
import CompanyInfo from '../../../components/Company/CompanyInfo.vue';
import WorkingHours from '../../../components/Company/WorkingHours.vue';
import CompanyDescription from '../../../components/Company/CompanyDescription.vue';
import CompanyFiles from '../../../components/Company/CompanyFiles.vue';
import Modal from '../../../components/Modal/Modal';
import ConfirmationModal from '../../../components/Modal/ConfirmationModal';
import CompanyProfilePhoto from '../../../components/Company/CompanyProfilePhoto';
import store from '../../../store';
import router from '../../../router';

export default {
  setup() {
    const isOpen = ref(false);

    const handleDeleteAcc = async () =>
      await store
        .dispatch('deleteAccount', store.getters.userInfo.id)
        .then(() => {
          router.push({ name: 'Home', query: { 'account-deleted': 1 } });
        });

    const handleLogout = async () => {
      await store.dispatch('logoutUser');
      router.push({ name: 'Home' });
    };

    return {
      isOpen,
      handleLogout,
      handleDeleteAcc,
    };
  },
  mounted() {
    if (this.$route.hash) {
      const el = document.querySelector(this.$route.hash);
      el && el.scrollIntoView();
    }
  },
  components: {
    CompanyProfilePhoto,
    ButtonElement,
    CompanyInfo,
    WorkingHours,
    CompanyDescription,
    CompanyFiles,
    Modal,
    ConfirmationModal,
  },
};
</script>

<style lang="scss">
.company {
  header {
    background-color: $hty-grey;
    border-radius: 0.625rem;
  }

  .company-logo {
    position: relative;

    .camera-icon {
      position: absolute;
      bottom: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.25rem;
      height: 2.25rem;
      line-height: 2.25rem;
      border-radius: 50%;
      background-color: $hty-medium-grey;
      cursor: pointer;
    }
  }

  .company-name {
    font-size: 1.25rem;
    color: $hty-orange;
  }

  .form-control-custom-custom-group {
    margin: 0 -1rem 1rem;
    display: flex;

    .form-control-custom {
      padding: 0 1rem;
    }

    .full-w {
      flex: 1 1 100%;
    }

    .half-w {
      flex: 0 0 50%;
    }
  }

  .delete-account-container {
    .subtitle {
      font-size: 1.5rem;
      line-height: 1.75rem;
      color: $hty-orange;
    }

    .flex-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
</style>
