<template>
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.1219 18.5859H20.6502"
      :stroke="color"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.8861 1.98077C16.3073 1.5804 16.8785 1.35547 17.4741 1.35547C17.7691 1.35547 18.0611 1.41069 18.3336 1.51798C18.6061 1.62527 18.8536 1.78253 19.0622 1.98077C19.2707 2.17902 19.4362 2.41437 19.549 2.67339C19.6619 2.93242 19.72 3.21003 19.72 3.4904C19.72 3.77076 19.6619 4.04838 19.549 4.3074C19.4362 4.56642 19.2707 4.80177 19.0622 5.00002L5.82852 17.5802L1.59375 18.5866L2.65244 14.561L15.8861 1.98077Z"
      :stroke="color"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#E58413',
    },
  },
};
</script>

<style lang="scss" scoped></style>
