<template>
  <div class="company-description">
    <div class="title">
      {{
        $t(
          `${$store.getters.getTranslationKeyForAdmin}.settingsCompany.companyDescription`
        )
      }}
    </div>

    <div class="form-control-custom-custom-group">
      <div class="form-control-custom full-w">
        <TextareaElement
          :placeholder="
            $t(
              `${$store.getters.getTranslationKeyForAdmin}.settingsCompany.companyDescription`
            )
          "
          v-model="description"
        />
      </div>
    </div>

    <div class="form-control-custom-custom-group">
      <div class="form-control-custom half-w">
        <ButtonElement style="max-width: 200px" block orange-secondary>
          {{
            $t(
              `${$store.getters.getTranslationKeyForAdmin}.settingsCompany.cancel`
            )
          }}
        </ButtonElement>
      </div>
      <div class="form-control-custom half-w">
        <ButtonElement
          @click="handleSave"
          :loading="isLoading"
          style="max-width: 200px; float: right"
          block
          orange
        >
          {{
            $t(
              `${$store.getters.getTranslationKeyForAdmin}.settingsCompany.save`
            )
          }}
        </ButtonElement>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';
import ButtonElement from '../FormControl/ButtonElement.vue';
import TextareaElement from '../FormControl/TextareaElement.vue';
import store from '../../store';

export default {
  setup() {
    let isLoading = ref(false);

    const description = ref(
      store.getters.userInfo.description
        ? store.getters.userInfo.description
        : ''
    );

    const handleSave = async () => {
      isLoading.value = true;
      await store.dispatch('updateUserInfo', {
        description: description.value,
      });
      isLoading.value = false;
    };

    return {
      description,
      handleSave,
      isLoading,
    };
  },
  components: { ButtonElement, TextareaElement },
};
</script>

<style lang="scss" scoped>
.company-description {
  padding: 2rem 0;
}

.title {
  text-align: center;
}

.space-top {
  margin-top: 3rem;
}
</style>
